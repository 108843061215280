import React, { useState } from "react";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { NavLeftPanel } from "../NavLeftPanel/NavLeftPanel";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./Content.css";
import { ContentTable } from "../ContentTable/ContentTable";
import { ContentTablePagination } from "../ContentTablePagination/ContentTablePagination";

export const Content = () => {
  const [refresh, setRefresh] = useState(false);
  const [isPPTActive, setIsPPTActive] = useState(true);
  const [isVideoActive, setIsVideoActive] = useState(false);

  const refreshStatus = () => {
    setRefresh((prevState) => !prevState);
  };

  return (
    <div className="content-container">
      <div className="content-left-panel">
        <NavLeftPanel />
      </div>

      <div className="content-right-panel">
        <div className="content-right-header">
          <LibraryBooksOutlinedIcon fontSize="large" />
          <h1>Content</h1>
        </div>

        <div className="content-right-body">
          <div className="content-right-body-header">
            <div onClick={refreshStatus} className="refresh-button-container">
              <button className="refresh-button">
                <RefreshIcon />
                <p>Refresh</p>
              </button>
            </div>
            <div className="ppt-video-select-container">
              <button
                onClick={() => {
                  setIsPPTActive(true);
                  setIsVideoActive(false);
                }}
                className={`ppt-button ${isPPTActive ? "active" : ""}`}
              >
                <p>PPTs</p>
              </button>
              <button
                onClick={() => {
                  setIsPPTActive(false);
                  setIsVideoActive(true);
                }}
                className={`video-button ${isVideoActive ? "active" : ""}`}
              >
                <p>Videos</p>
              </button>
            </div>
          </div>

          {/* Show the chapter table */}

          <div className="content-right-body-table">
            {/* <ContentTable refresh={refresh} /> */}
            <ContentTablePagination
              refresh={refresh}
              isPPTActive={isPPTActive}
              isVideoActive={isVideoActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
