import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import "./Home.css";
import "../Global.css";
import { NavLeftPanel } from "../NavLeftPanel/NavLeftPanel";
import { VideoUpload } from "../VideoUpload/VideoUpload";
import { UplaodOptions } from "../UploadOptions/UploadOptions";

export const Home = () => {
  return (
    <div className="home-container">
      <div className="home-left-panel">
        <NavLeftPanel />
      </div>

      <div className="home-right-panel">
        <div
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
          className="home-right-header"
        >
          <HomeIcon fontSize="large" />
          <h1>Home</h1>
        </div>

        <div className="home-right-body">
          {/* <VideoUpload /> */}
          <UplaodOptions />
        </div>
      </div>
    </div>
  );
};
