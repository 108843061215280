import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import axiosInstance from "../Axios";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import Cookies from "js-cookie";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";
import "./ContentTablePagination.css";
import { TableFooter } from "@mui/material";
import { AssignUserModal } from "../AssignUserModal/AssignUserModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: left;
      padding: 8px;
    }
  
    th {
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    }
    `
);

const CustomTablePagination = styled(TablePagination)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
    margin-left: 1rem;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

function createData(id, type, name, status, url) {
  return { id, type, name, status, url };
}

export const ContentTablePagination = ({
  refresh,
  isVideoActive,
  isPPTActive,
}) => {
  const navigate = useNavigate();

  const user_type_id = Cookies.get("user_type_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [contentPPT, setContentPPT] = useState([]);
  const [contentVideo, setContentVideo] = useState([]);
  const [allContent, setAllContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContentData = async () => {
    try {
      setLoading(true);
      if (isPPTActive) {
        const res1 = await axiosInstance.get("api/admin/get_all_ppts");
        const transformedPPT = res1.data.data.map((ppt) =>
          createData(ppt.ppt_id, "PPT", ppt.ppt_name, ppt.status, ppt.ppt_uri)
        );
        setContentPPT(transformedPPT);
        setAllContent([...transformedPPT]);
      }

      if (isVideoActive) {
        const res2 = await axiosInstance.get(
          "/api/miscellaneous/get_all_processing_videos"
        );
        // const res2 = await axiosInstance.get("api/admin/get_all_videos");
        const transformedVideo = res2.data.videos.map((video) =>
          createData(
            video.video_id,
            "Video",
            video.name,
            video.status,
            video.video_url
          )
        );
        setContentVideo(transformedVideo);
        setAllContent([...transformedVideo]);
      }
      //   setAllContent([...transformedPPT, ...transformedVideo]);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const contentDownload = (url) => {
    if (url === null) return alert("Content not available");

    return window.open(url);
  };

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - (contentPPT.length + contentVideo.length)
        )
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getContentData();
  }, [refresh, isPPTActive, isVideoActive]);

  return (
    <>
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : (
        <>
          <div>
            <TableContainer>
              <Table sx={{ minWidth: "700px" }} aria-label="customized table">
                {contentPPT.length === 0 ? (
                  <Typography variant="h6">No Data</Typography>
                ) : (
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Type</StyledTableCell>
                      <StyledTableCell align="left">Name</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                      <StyledTableCell align="left">Action</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      {user_type_id == 1 && (
                        <StyledTableCell align="left">
                          Assign User
                        </StyledTableCell>
                      )}
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {(rowsPerPage > 0
                    ? allContent.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : allContent
                  ).map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="left" scope="row">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {row.type}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: "bold" }} align="left">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (row.type === "PPT") {
                              if (
                                row.url === null ||
                                row.status === "IMAGE_EXTRACTION_FAILED"
                              ) {
                                alert("No PPT Available");
                                return;
                              }
                              navigate(`/content/${row.id}`);
                            }

                            if (row.type === "Video") {
                              if (row.url === null) {
                                alert("No Video Available");
                                return;
                              }
                              navigate(`/video/${row.id}`);
                            }
                          }}
                        >
                          {row.name}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.status}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div
                          onClick={() => contentDownload(row.url)}
                          className="card-icons-tray-container"
                        >
                          <DownloadIcon sx={{ cursor: "pointer" }} />
                        </div>
                      </StyledTableCell>

                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>

                      {user_type_id == 1 && (
                        <StyledTableCell align="left">
                          <AssignUserModal id={row.id} type={row.type} />
                        </StyledTableCell>
                      )}

                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                  ))}

                  {emptyRows > 0 && (
                    <tr style={{ height: 41 * emptyRows }}>
                      <td colSpan={3} aria-hidden />
                    </tr>
                  )}
                </TableBody>

                <TableFooter>
                  <StyledTableRow></StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <div className="content-table-pagination-container">
              <CustomTablePagination
                rowsPerPageOptions={[10, 20, 30, { label: "All", value: -1 }]}
                colSpan={3}
                count={allContent.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
