import React ,{useEffect, useRef, useState} from "react";
import "./VideoSeekerAnima.css";
import { Slider } from "./Slider/Slider";
import { ControlPanel } from "./ControlPanel/ControlPanel";

export const VideoSeekerAnima = ({audio}) => {

  const [song, setSong] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const [disablePlay, setDisablePlay] = useState(false);

  const audioRef = useRef();

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const play = () => {
    const audio = audioRef.current;
    audio.volume = 0.5;

    if(!isPlaying){
      setIsPlaying(true);
      audio.play();
    }

    if(isPlaying){
      setIsPlaying(false);
      audio.pause();
    }
  }

  const getCurrDuration = (e) => {
    const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  }

  useEffect(()=> {
    setSong(audio);
    if(audio === null){
      setDisablePlay(true);
    }else{
      setDisablePlay(false);
    }
  }, [audio])

  return (
    <div className="frame">
      <div className="play-button-div">

        {
          song === null || disablePlay ? (
            <button className="play-btn-play" disabled style={{backgroundColor: "gray", cursor:"default"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M4 3.76872C4 3.19805 4.61133 2.83672 5.11133 3.11139L12.8047 7.34339C12.9223 7.40813 13.0203 7.50325 13.0886 7.61881C13.1569 7.73438 13.1929 7.86615 13.1929 8.00039C13.1929 8.13462 13.1569 8.2664 13.0886 8.38196C13.0203 8.49753 12.9223 8.59264 12.8047 8.65739L5.11133 12.8887C4.99717 12.9515 4.86863 12.9834 4.73837 12.9814C4.60811 12.9794 4.48063 12.9435 4.36847 12.8772C4.25631 12.8109 4.16334 12.7166 4.09873 12.6035C4.03412 12.4903 4.0001 12.3623 4 12.2321V3.76872Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          ) : (
            <button className="play-btn-play" onClick={play}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M4 3.76872C4 3.19805 4.61133 2.83672 5.11133 3.11139L12.8047 7.34339C12.9223 7.40813 13.0203 7.50325 13.0886 7.61881C13.1569 7.73438 13.1929 7.86615 13.1929 8.00039C13.1929 8.13462 13.1569 8.2664 13.0886 8.38196C13.0203 8.49753 12.9223 8.59264 12.8047 8.65739L5.11133 12.8887C4.99717 12.9515 4.86863 12.9834 4.73837 12.9814C4.60811 12.9794 4.48063 12.9435 4.36847 12.8772C4.25631 12.8109 4.16334 12.7166 4.09873 12.6035C4.03412 12.4903 4.0001 12.3623 4 12.2321V3.76872Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          )
        }

        {/* {song !== null ? (<button className="play-btn-play" onClick={play}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M4 3.76872C4 3.19805 4.61133 2.83672 5.11133 3.11139L12.8047 7.34339C12.9223 7.40813 13.0203 7.50325 13.0886 7.61881C13.1569 7.73438 13.1929 7.86615 13.1929 8.00039C13.1929 8.13462 13.1569 8.2664 13.0886 8.38196C13.0203 8.49753 12.9223 8.59264 12.8047 8.65739L5.11133 12.8887C4.99717 12.9515 4.86863 12.9834 4.73837 12.9814C4.60811 12.9794 4.48063 12.9435 4.36847 12.8772C4.25631 12.8109 4.16334 12.7166 4.09873 12.6035C4.03412 12.4903 4.0001 12.3623 4 12.2321V3.76872Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        ) : (
          <button className="play-btn-play" disabled style={{backgroundColor: "gray", cursor:"default"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M4 3.76872C4 3.19805 4.61133 2.83672 5.11133 3.11139L12.8047 7.34339C12.9223 7.40813 13.0203 7.50325 13.0886 7.61881C13.1569 7.73438 13.1929 7.86615 13.1929 8.00039C13.1929 8.13462 13.1569 8.2664 13.0886 8.38196C13.0203 8.49753 12.9223 8.59264 12.8047 8.65739L5.11133 12.8887C4.99717 12.9515 4.86863 12.9834 4.73837 12.9814C4.60811 12.9794 4.48063 12.9435 4.36847 12.8772C4.25631 12.8109 4.16334 12.7166 4.09873 12.6035C4.03412 12.4903 4.0001 12.3623 4 12.2321V3.76872Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        )} */}

      </div>
      <Slider onChange={onChange} percentage={percentage}/>
      <audio ref={audioRef} src={song} 
        onLoadedData={(e)=> {
          setDuration(e.currentTarget.duration.toFixed(2));
        }}
        onTimeUpdate={getCurrDuration}
      ></audio>
      <ControlPanel 
        play={play}
        isPlaying={isPlaying}
        duration={duration}
        currentTime={currentTime}
        audioRef={audioRef}
      />
    </div>
  );
};
