import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axiosInstance from "../Axios";
import "./PPTUpload.css";

export const PPTUpload = () => {
  const [pptFile, setPptFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);

  const [langDict, setLangDict] = useState();

  const [videoName, setVideoName] = useState("");
  const [allEngines, setAllEngines] = useState([]);
  const [ttsEngine, setTTSEngine] = useState("");
  const [language, setLanguage] = useState("");
  const [speaker, setSpeaker] = useState("");
  const [threshold, setThreshold] = useState(0.44);

  const [uploading, setUploading] = useState(false);

  const getLangDict = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/language/get_language_data_with_language_as_key"
      );
      setLangDict(res.data.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllEngines = async () => {
    try {
      const res = await axiosInstance.get("/api/miscellaneous/get_all_engines");
      setAllEngines(res.data.engines);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleVideoNameChange = (event) => {
    setVideoName(event.target.value);
  };

  const handleTTSChange = (event) => {
    setTTSEngine(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSpeakerChange = (event) => {
    setSpeaker(event.target.value);
  };

  const handleThresholdChange = (event) => {
    let value = event.target.value;
    // Ensure the value is a number
    value = parseFloat(value);

    // Check if the value is within the range [0, 1]
    if (value >= 0 && value <= 1) {
      setThreshold(value);
    }
  };

  const videoFormTwoSumbitHandler = async () => {
    if (pptFile === null) {
      alert("Please upload a PPT file");
      return;
    }

    if (pdfFile === null) {
      alert("Please upload a PDF file");
      return;
    }

    if (
      videoName === "" ||
      videoName.trim() === "" ||
      ttsEngine === "" ||
      language === "" ||
      speaker === ""
    ) {
      alert("Please fill all the fields");
      return;
    }

    try {
      setUploading((prevState) => !prevState);
      const formData = new FormData();
      formData.append("ppt_name", videoName);
      formData.append("ppt_file", pptFile);
      formData.append("pdf_file", pdfFile);
      formData.append("engine", ttsEngine);
      formData.append("ppt_lang", langDict[language]);
      formData.append("speaker", speaker);
      formData.append("chapter_id", 0);
      const res = await axiosInstance.post(
        "api/ppt/ppt_pdf_receiver",
        formData
      );
      console.log("Submit res", res.data.request_id);
      setVideoName("");
      setTTSEngine("");
      setLanguage("");
      setSpeaker("");
      setPdfFile(null);
      setPptFile(null);
      setThreshold(0.44);
      setUploading((prevState) => !prevState);
      alert("PPT uploaded successfully");
    } catch (err) {
      alert("Something went wrong");
      console.error(err.message);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const uploadedFile = e.dataTransfer.files[0];
    handleFile(uploadedFile);
  };

  const handleFileInput = (e) => {
    const uploadedFile = e.target.files[0];
    handleFile(uploadedFile);
  };

  const handleFile = (uploadedFile) => {
    if (uploadedFile) {
      if (uploadedFile.type === "application/pdf") {
        setPdfFile(uploadedFile);
      } else if (
        uploadedFile.type === "application/vnd.ms-powerpoint" ||
        uploadedFile.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        setPptFile(uploadedFile);
      } else {
        alert("Please upload a single PPT and PDF file.");
      }
    }
  };

  const handleRemovePPT = () => {
    setPptFile(null);
  };

  const handleRemovePDF = () => {
    setPdfFile(null);
  };

  useEffect(() => {
    getAllEngines();
    getLangDict();
  }, []);

  useEffect(() => {
    if (ttsEngine !== "") {
      setSpeaker("");
      setLanguage("");
    }
  }, [ttsEngine]);

  useEffect(() => {
    if (language !== "") {
      setSpeaker("");
    }
  }, [language]);

  return (
    <div>
      <div
        className="drop-area"
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => e.preventDefault()}
      >
        <p>Drag & drop a single PPT or PDF file here, or click to browse</p>
        <input
          type="file"
          accept=".ppt, .pptx, .pdf"
          onChange={(e) => handleFileInput(e)}
        />
      </div>
      {pptFile && (
        <div className="file-info">
          <p>PPT File: {pptFile.name}</p>
          <button className="remove-upload-file-btn" onClick={handleRemovePPT}>
            Remove PPT
          </button>
        </div>
      )}
      {pdfFile && (
        <div className="file-info">
          <p>PDF File: {pdfFile.name}</p>
          <button className="remove-upload-file-btn" onClick={handleRemovePDF}>
            Remove PDF
          </button>
        </div>
      )}

      <div style={{ marginTop: "20px" }} className="video-form-one-container">
        <div className="video-form-input">
          <TextField
            onChange={handleVideoNameChange}
            value={videoName}
            fullWidth
            id="outlined-basic"
            label="PPT Name"
            variant="outlined"
          />
        </div>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Text to speech engine
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={ttsEngine}
            label="Text to speech engine"
            onChange={handleTTSChange}
          >
            {Object.keys(allEngines).map((engine, index) => (
              <MenuItem key={index} value={engine}>
                {engine}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label="Language"
            onChange={handleLanguageChange}
          >
            {allEngines[ttsEngine] ? (
              Object.keys(allEngines[ttsEngine]).map((lang, index) => (
                <MenuItem key={index} value={lang}>
                  {lang}
                </MenuItem>
              ))
            ) : (
              <MenuItem sx={{ color: "red" }}>Please select a engine</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Speaker</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={speaker}
            label="Speaker"
            onChange={handleSpeakerChange}
          >
            {allEngines[ttsEngine] && allEngines[ttsEngine][language] ? (
              Object.values(allEngines[ttsEngine][language]).map(
                (speaker, index) => (
                  <MenuItem key={index} value={speaker}>
                    {speaker}
                  </MenuItem>
                )
              )
            ) : (
              <MenuItem sx={{ color: "red" }}>
                Please select a language
              </MenuItem>
            )}
          </Select>
        </FormControl>

        {/* <div className="video-form-input">
              <TextField fullWidth id="outlined-basic" label="Entropy Sensitivity Level(0-1)" variant="outlined"
                  InputProps={{
                      inputProps: {
                          step: 0.01, // Allow increments of 0.01
                          min: 0,     // Minimum value
                          max: 1,     // Maximum value
                          type: 'number',
                  },
                      inputComponent: 'input',
                  }}
                  value={threshold}
                  onChange={handleThresholdChange}
              />
          </div> */}

        <div className="video-form-input">
          {uploading ? (
            <button
              type="submit"
              className="video-form-submit-btn"
              style={{ backgroundColor: "grey", cursor: "not-allowed" }}
            >
              Uploading...
            </button>
          ) : (
            <button
              type="submit"
              className="video-form-submit-btn"
              onClick={videoFormTwoSumbitHandler}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
