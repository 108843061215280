import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export const PPTPageSkeleton = () => {
  return (
    <div className="main-container">

        <div className="left-panel-container">

            <div className="left-panel-navigation">

                <div className='icon-container'>

                    <Skeleton variant="circular" width={40} height={40} />

                </div> 

                <div className="left-panel-text-container">

                    <Skeleton variant="text" sx={{ fontSize: '3rem', marginLeft:"5px" }} width={255}/>

                </div> 

            </div>

            <div className="slide-preview-container-wrapper">

                <div className="slide-preview-container">

                    <Skeleton variant="rounded" width={300} height={180} />

                    <Skeleton variant="rounded" width={300} height={180} />

                    <Skeleton variant="rounded" width={300} height={180} />

                </div>

            </div>

        </div>

        <div className="right-panel-container">

            <Skeleton variant="rounded" width={900} height={400} />

            <div style={{display:"flex", alignItems: "center"}}>

                <Skeleton variant="circular" width={40} height={40} />

                <Skeleton variant="text" sx={{ fontSize: '3rem', marginLeft:"10px" }} width={850}/>

            </div>

            <Skeleton variant="rounded" width={900} height={100} />


        </div>

    </div>
  )
}
