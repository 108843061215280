import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import videoLogo from "../../media/video-to-video.jpeg"
import './SignIn.css'
import "../Global.css"
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../Axios'

export const SignIn = () => {

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const signInFormSubmission = async (e) => {
            
        e.preventDefault();

        if(loading){
            return;
        }

        if(username === "" || username.trim() === "") {
            setUsernameError("Please enter username");
        } else {
            setUsernameError("");
        }

        if(password === "" || password.trim() === "") {
            setPasswordError("Please enter password");
        } else {
            setPasswordError("");
        }

        if(username && password) {
            setLoading(true);

            const loginData = new FormData();
            loginData.append('username', username);
            loginData.append('password', password);
            
            try {
                
                const res = await axiosInstance.post('/api/auth/login', loginData);

                if(res.data.status){
                    Cookies.set("Token", res.data.data.auth_token);
                    Cookies.set("username", res.data.data.username);
                    Cookies.set("user_id", res.data.data.user_id);
                    Cookies.set("user_type_id", res.data.data.user_type_id);

                    navigate("/home");

                }else{
                    setPasswordError("Some problem occurred. Please try again.");
                }

            } catch (err) {
                if(err.response){
                    console.log("error", err)
                    setPasswordError(err.response.data.message);
                }else{
                    setPasswordError("Something went wrong");
                }
                setLoading(false);
            }

        }

    }

    useEffect(()=> {
        setUsernameError("");
    }, [username]);

    useEffect(()=> {
        setPasswordError("");
    }, [password]);

  return (
    <div className='signin-container'>

        <div className='signin-left-panel'>
            
            <div className='branding'>
            
                <h1>Video to Video Translation</h1>

            </div>

        </div>

        <div className='signin-right-panel'>
            <form className='signin-form'
                onSubmit={(e)=> {signInFormSubmission(e)}}
            >
                <h2 id='signin-form-heading'>Sign In</h2>

                <input type='text' id='username' className="signin-form-fields" placeholder='Username'
                    onChange={(e) => {setUsername(e.target.value);}}
                />

                <span id="signin-form-username-error" className="signin-form-input-error">{usernameError}</span>

                <input type='password' id='password' className="signin-form-fields" placeholder='Password' 
                    onChange={(e)=> {setPassword(e.target.value);}}
                />
                
                <span id="signin-form-password-error" className="signin-form-input-error">{passwordError}</span>

                {
                    loading ? (
                        <button disabled style={{backgroundColor: "grey", cursor: "not-allowed"}} type='submit' className='signin-form-fields' id='signin-form-submit'>Loading...</button>
                    ) : (
                        <button type='submit' className='signin-form-fields' id='signin-form-submit'>Sign In</button>
                    )
                 }

            </form>
        </div>
        
    </div>
  )
}
