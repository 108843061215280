import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LogOutIcon from "../../media/LogOutIcon.svg"
import { NavLink, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link';

import './NavLeftPanel.css'
import "../Global.css"

import { Typography } from '@mui/material';
import axiosInstance from '../Axios';
import Cookies from 'js-cookie';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'This is a beta version'}
        {/* <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link> */}
      </Typography>
    );
  }


export const NavLeftPanel = () => {

    const navigate = useNavigate();

    const logoutAPI = (e) => {

        axiosInstance.get("/api/auth/logout").then((res) => {
            if(res.data.status){
                Cookies.remove("Token")
                Cookies.remove("username")
                Cookies.remove("user_type_id")
                Cookies.remove("user_id")
                navigate("/")
                window.location.reload();
            }


        }).catch((error) => {
                console.log(error.message);
                alert("Something went wrong. Please try again later.");
            // if(error.response.data.message !== "Missing auth token" || error.response.data.message !== "Invalid auth token"){
            // }
        });

    }

  return (
    <div className="navleft-panel-container">

        <div className="navleft-panel-top">
            <div className="navleft-panel-header">
                <h2>Aveti Learning</h2>
            </div>

            <div className="navleft-panel-body">
                <nav id="navleft-sidebar">
                    <NavLink to="/home" className="navleft-options">
                        <HomeIcon />
                        <Typography variant='body1' sx={{fontWeight: "bold"}}>Home</Typography>
                    </NavLink>
                    <NavLink to="/content" className="navleft-options">
                        <LibraryBooksOutlinedIcon />
                        <Typography variant='body1' sx={{fontWeight: "bold"}}>Content</Typography>
                    </NavLink>
                    {/* <NavLink to="/users" className="navleft-options">
                        <GroupIcon />
                        <Typography variant='body1' sx={{fontWeight: "bold"}}>Users</Typography>
                    </NavLink> */}
                    <div className="navleft-options" style={{display:"flex", cursor: "pointer"}} onClick={(e) => logoutAPI(e)}>
                        <img src={LogOutIcon} alt='Log Out' />
                        <Typography variant='body1' sx={{fontWeight: "bold"}}>Logout</Typography>
                    </div>
                </nav>
            </div>
        </div>
        <Copyright sx={{ mb: 3 }}/>
    </div>
  )
}
