import { Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../Axios'
import { ReactPlayerSkeleton } from './ReactPlayerSkeleton/ReactPlayerSkeleton'
import "./VideoPage.css"

export const VideoPage = () => {

    const navigate = useNavigate();

    const {id} = useParams();
    const [chapterVideo, setChapterVideo] = useState([]);
    const [videoEntry, setVideoEntry] = useState({
        
    });


    const getVideos = async () => {

        try {
            const res = await axiosInstance.get("/api/miscellaneous/get_all_processing_videos");
            setChapterVideo(res.data.videos);
        } catch (err) {
            console.error(err.message);
        }

    }

    const getVideoEntry = () => {

        setVideoEntry(chapterVideo.filter((video)=> video.video_id == id));

    }

    useEffect(()=> {

        getVideos();
        getVideoEntry();

    }, [])

    useEffect(()=> {
        getVideoEntry();
    }, [chapterVideo])

  return (
    <div className="react-player-container">

        {videoEntry.length > 0 ? (
            <>

                <div className='react-player-right'>

                    <div className="react-player-header">

                        <div onClick={()=> navigate("/content")} style={{cursor: "pointer"}} className="icon-container">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_159_481" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_159_481)">
                                    <path d="M9.07499 21.0734L0.749988 12.7484C0.633321 12.6318 0.549988 12.5109 0.499988 12.3859C0.449988 12.2609 0.424988 12.1318 0.424988 11.9984C0.424988 11.8651 0.449988 11.7359 0.499988 11.6109C0.549988 11.4859 0.633321 11.3651 0.749988 11.2484L9.09999 2.89844C9.34999 2.64844 9.65415 2.52344 10.0125 2.52344C10.3708 2.52344 10.675 2.65677 10.925 2.92344C11.1917 3.17344 11.3167 3.48177 11.3 3.84844C11.2833 4.2151 11.15 4.52344 10.9 4.77344L3.67499 11.9984L10.925 19.2484C11.1583 19.4818 11.275 19.7859 11.275 20.1609C11.275 20.5359 11.1583 20.8401 10.925 21.0734C10.675 21.3234 10.3667 21.4484 9.99999 21.4484C9.63332 21.4484 9.32499 21.3234 9.07499 21.0734Z" fill="#212121"/>
                                </g>
                            </svg>

                        </div>
                        
                        <Typography variant="h4" sx={{color: "black"}}>{videoEntry[0].name}</Typography>

                    </div>

                    <div className="react-player-body">

                        <ReactPlayer
                            controls={true}
                            url={videoEntry[0].video_url}
                            height="500px"
                            width="889px"
                        >
                        </ReactPlayer>

                    </div>
                    
                </div>
                
            </>
        ) : (
            <>
                <ReactPlayerSkeleton />
            </>
        )}

        
    </div>
  )
}
