import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from '../Axios';
import "./AssignUserModal.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
};

function sleep(duration) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }

export const AssignUserModal = ({id, type}) => {

    const [openUserSearch, setOpenUserSearch] = useState(false);
    const [assignedUser, setAssignedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const loading = openUserSearch && users.length === 0;
    const [content_ids, setContent_ids] = useState([]);

    const [reload, setReload] = useState(false);
    const [resMessage, setResMessage] = useState("");
    const [showResMessage, setShowResMessage] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getUsers = async () => {

        try {

            if(type == "PPT"){
                const res = await axiosInstance.get(`/api/ppt/get_users_not_assigned_to_ppt?ppt_id=${id}`);
                setUsers(res.data.data);
            } else {
                const res = await axiosInstance.get(`/api/videos/get_users_not_assigned_for_video_review?video_id=${id}`);
                setUsers(res.data.data);
            }

            
        } catch (err) {
            console.log(err.message);
        }
    }

    const assignUser = async () => {

        if(assignUser === null){
            setResMessage("Please select a user!");
            setShowResMessage(true);
            return;
        }

        try {

            if(type == "PPT"){
                const res = await axiosInstance.post(`/api/ppt/assign_ppt_for_review?ppt_id=${id}&user_id=${assignedUser}`);    
            }else {
                const res = await axiosInstance.post(`/api/videos/assign_video_for_review?video_id=${id}&user_id=${assignedUser}`);
            }
            
            setReload(true);
            setResMessage("User Assigned Successfully!");
            setShowResMessage(true);
            setAssignedUser(null);

            setTimeout(() => {
                setShowResMessage(false);
                setAssignedUser(null);
                handleClose();
            }, 2000);

        } catch (err) {
            setAssignedUser(null);
            if(assignedUser === null){
                setResMessage("Please select a user!");
                setShowResMessage(true);
                return;
            }
            setResMessage("Something went wrong!");
            setShowResMessage(true);
            console.error(err.response.data.message);
        }
    }

    useEffect(() => {
        if(open){
            getUsers();
        }
        if (reload) {
            setReload(false);
        }
    }, [open,reload]);

    useEffect(() => {
        let active = true;
        
        if (!loading) {
          return undefined;
        }
    
        (async () => {
          await sleep(1e3); // For demo purposes.
    
          if (active) {
            setUsers([...users]);
          }
        })();
    
        return () => {
          active = false;
        };
      }, [loading]);
    
    //   useEffect(() => {
    //     if (!openUserSearch) {
    //       setUsers([]);
    //     }
    //   }, [openUserSearch]);

  return (
    <>
        <button onClick={handleOpen} className="assign-user-btn">Assign User</button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    showResMessage ? (

                        resMessage === "User Assigned Successfully!" ? (
                            <Typography component="span" variant="body2" sx={{color: "green", display: showResMessage ? "block" : "none", marginBottom:"20px"}}>
                                {resMessage}
                            </Typography>
                        ) : (
                            <Typography component="span" variant="body2" sx={{color: "red", display: showResMessage ? "block" : "none", marginBottom:"20px"}}>
                                {resMessage}
                            </Typography>
                        )

                    ) : null
                }

                <Box sx={{ minWidth: 120 }}>

                    <Autocomplete
                        id="asynchronous-demo"
                        sx={{ width: 300 }}
                        open={openUserSearch}
                        onOpen={() => {
                            setOpenUserSearch(true);
                        }}
                        onClose={() => {
                            setOpenUserSearch(false);
                        }}
                        onChange={(event, newValue) => {
                            // setSelectedOption(newValue);
                            if(newValue === null){
                                setAssignedUser(null);
                                return;
                            }else{
                                setAssignedUser(newValue.user_id);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                        getOptionLabel={(option) => option.username}
                        options={users}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Assign User"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                                ),
                            }}
                            />
                        )}
                    />
                    {/* <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Assign User</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={assignedUser}
                        label="Assign User"
                        onChange={handleAssignUser}
                        >
                            {users.map((user)=>(
                                <MenuItem value={user.user_id}>{user.username}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                </Box>
                <Button onClick={assignUser} variant="contained" sx={{marginTop: "20px"}}>Assign</Button>
            </Box>
        </Modal>
    </>
  )
}
