import { Typography } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone';

import "./VideoUploadDropzone.css"

const allowedFileTypes = [
    'video/mp4'
]

export const VideoUploadDropzone = ({parentVideo, setParentVideoFile, videoUploading}) => {

    

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && allowedFileTypes.includes(file.type)) {
            setParentVideoFile(file);
        }else{
            alert('File type not supported. Only mp4 files are allowed.')
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: allowedFileTypes.join(','),
        multiple: false
    });

    const removeFile = () => {
        setParentVideoFile(null);
    };

  return (
    <div className='video-dropzone-container'>



        <div className="file-upload-container">

            <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>Drag & drop a MP4 video here, or click to select a file</p>
            </div>
            {parentVideo && (
            <div className="file-preview">
                {parentVideo.type.startsWith('image') ? (
                 null
                ) : (
                <video controls className="preview-video">
                    <source src={URL.createObjectURL(parentVideo)} type="video/mp4" />
                </video>
                )}
                <div></div>
                {/* <p className="success-message">File uploaded successfully!</p> */}
                {
                    videoUploading ? (
                        <button className="remove-button" style={{backgroundColor: "grey", cursor: "not-allowed"}}>
                            Remove
                        </button>
                    ) : (
                        <button className="remove-button" onClick={removeFile}>
                            Remove
                        </button>
                    )
                }
            </div>
            )}

        </div>

    </div>
  )
}
