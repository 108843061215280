import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./VideoFormOne.css";
import axiosInstance from "../Axios";

export const VideoFormOne = ({
  videoFile,
  setVideoFile,
  setParentVideoUploading,
  getIntermediatePPT,
}) => {
  const [videoName, setVideoName] = useState("");
  // const [language, setLanguage] = useState('');
  const [targetLanguage, setTargetLanguage] = useState("");
  const [threshold, setThreshold] = useState(0.44);

  const [uploading, setUploading] = useState(false);

  const handleVideoNameChange = (event) => {
    setVideoName(event.target.value);
  };

  // const handleChangeLanguage = (event) => {
  //     setLanguage(event.target.value);
  // }

  const handleChangeTargetLanguage = (event) => {
    setTargetLanguage(event.target.value);
  };

  const handleThresholdChange = (event) => {
    let value = event.target.value;
    // Ensure the value is a number
    value = parseFloat(value);

    // Check if the value is within the range [0, 1]
    if (value >= 0 && value <= 1) {
      setThreshold(value);
    }
  };

  const videoFormOneSumbitHandler = async () => {
    if (videoFile === null) {
      alert("Please upload a video file");
      return;
    }

    if (videoName === "" || videoName.trim() === "") {
      alert("Please fill all the fields");
      return;
    }

    try {
      setUploading((prevState) => !prevState);
      setParentVideoUploading((prevState) => !prevState);
      const formData = new FormData();
      formData.append("video_name", videoName);
      formData.append("video", videoFile);
      formData.append("target_language", targetLanguage);
      formData.append("threshold", threshold);
      formData.append("get_intermediate_ppt", getIntermediatePPT);
      const res = await axiosInstance.post(
        "api/miscellaneous/save_video_for_processing",
        formData
      );
      console.log("Submit res", res.data.request_id);
      setVideoName("");
      setTargetLanguage("");
      setThreshold(0.44);
      setVideoFile(null);
      setUploading((prevState) => !prevState);
      setParentVideoUploading((prevState) => !prevState);
      alert("Video uploaded successfully");
    } catch (err) {
      setUploading((prevState) => !prevState);
      setParentVideoUploading((prevState) => !prevState);
      alert("Error uploading video");
      console.error(err.message);
    }
  };

  return (
    <div className="video-form-one-container">
      <div className="video-form-input">
        <TextField
          onChange={handleVideoNameChange}
          value={videoName}
          fullWidth
          id="outlined-basic"
          label="Video Name"
          variant="outlined"
        />
      </div>

      {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Source Language</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label="Source Language"
                onChange={handleChangeLanguage}
            >
                <MenuItem value="Hindi">Hindi</MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Odia">Odia</MenuItem>
            </Select>
        </FormControl> */}

      {/* <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Target Language</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={targetLanguage}
          label="Target Language"
          onChange={handleChangeTargetLanguage}
        >
          <MenuItem value="hi">Hindi</MenuItem>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="or">Odia</MenuItem>
        </Select>
      </FormControl> */}

      <div className="video-form-input">
        <TextField
          fullWidth
          id="outlined-basic"
          label="Entropy Sensitivity Level(0-1)"
          variant="outlined"
          InputProps={{
            inputProps: {
              step: 0.01, // Allow increments of 0.01
              min: 0, // Minimum value
              max: 1, // Maximum value
              type: "number",
            },
            inputComponent: "input",
          }}
          value={threshold}
          onChange={handleThresholdChange}
        />
      </div>

      <div className="video-form-input">
        {uploading ? (
          <button
            type="submit"
            className="video-form-submit-btn"
            style={{ backgroundColor: "grey", cursor: "not-allowed" }}
          >
            Uploading...
          </button>
        ) : (
          <button
            type="submit"
            className="video-form-submit-btn"
            onClick={videoFormOneSumbitHandler}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};
