import { SignIn } from './components/SignIn/SignIn';
import { Routes, Route, HashRouter } from 'react-router-dom';
import './App.css';
import { Home } from './components/Home/Home';
import { Content } from './components/Content/Content';
import { PPTPage } from './components/PPTPage/PPTPage';
import { VideoPage } from './components/VideoPage/VideoPage';

function App() {

  console.log("build version - 04.11.2023-1 - Added beta version tag and pagination to content table");

  return (

    <HashRouter basename="/">

      <Routes>
        <Route path='/' element={ <SignIn /> } />
        <Route path='/home' element={ <Home /> } />
        <Route path='/content' element={ <Content /> } />
        <Route path='/content/:id' element={ <PPTPage />} />
        <Route path='/video/:id' element={ <VideoPage />} />
      </Routes>

    </HashRouter>
  );
}

export default App;
