import React, { useState } from "react";
import { VideoUploadDropzone } from "../VideoUploadDropzone/VideoUploadDropzone";
import { VideoFormOne } from "../VideoFormOne/VideoFormOne";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axiosInstance from "../Axios";
import { VideoFormTwo } from "../VideoFormTwo/VideoFormTwo";
import "./VideoUpload.css";
import "../Global.css";

export const VideoUpload = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoUploading, setVideoUploading] = useState(false);
  const [getIntermediatePPT, setGetIntermediatePPT] = useState(false);

  const handleVideoFile = (file) => {
    setVideoFile(file);
  };

  const handleVideoUploading = (value) => {
    setVideoUploading(value);
  };

  const handleGetIntermediatePPTChange = (event) => {
    setGetIntermediatePPT(event.target.checked);
  };

  return (
    <div className="video-upload-container">
      <VideoUploadDropzone
        parentVideo={videoFile}
        setParentVideoFile={handleVideoFile}
        videoUploading={videoUploading}
      />

      <div className="video-upload-form-container">
        <FormGroup sx={{ marginBottom: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={getIntermediatePPT}
                onChange={handleGetIntermediatePPTChange}
              />
            }
            label="Is manual intervention required?"
          />
        </FormGroup>

        {getIntermediatePPT ? (
          <div
            style={{ width: 475 }}
            className="video-upload-form-one-container"
          >
            <VideoFormTwo
              getIntermediatePPT={getIntermediatePPT}
              videoFile={videoFile}
              setVideoFile={setVideoFile}
              setParentVideoUploading={handleVideoUploading}
            />
          </div>
        ) : (
          <div
            style={{ width: 475 }}
            className="video-upload-form-one-container"
          >
            <VideoFormOne
              getIntermediatePPT={getIntermediatePPT}
              videoFile={videoFile}
              setVideoFile={setVideoFile}
              setParentVideoUploading={handleVideoUploading}
            />
          </div>
        )}
      </div>
    </div>
  );
};
