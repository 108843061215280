import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import axiosInstance from "../Axios";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";
import "./ContentTable.css";
import { TableFooter } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: "black",
    fontSize: 20,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ContentTable = ({ refresh }) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [contentPPT, setContentPPT] = useState([]);
  const [contentVideo, setContentVideo] = useState([]);
  const [allContent, setAllContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContentData = async () => {
    try {
      setLoading(true);
      const res2 = await axiosInstance.get(
        "/api/miscellaneous/get_all_processing_videos"
      );
      const res1 = await axiosInstance.get("api/admin/get_all_ppts");
      // const res2 = await axiosInstance.get("api/admin/get_all_videos");

      setContentPPT(res1.data.data);
      setContentVideo(res2.data.videos);
      setAllContent([...res1.data.data, ...res2.data.videos]);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const pptDownload = (url) => {
    if (url === null) return alert("No PPT Available");

    return window.open(url);
  };

  const videoDownload = (url) => {
    if (url === null) return alert("No Video Available");

    return window.open(url);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allContent.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getContentData();
  }, [refresh]);

  return (
    <>
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: "700px" }} aria-label="customized table">
            {contentPPT.length === 0 ? (
              <Typography variant="h6">No Data</Typography>
            ) : (
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Type</StyledTableCell>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {/* {(rowsPerPage > 0
                                ? allContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : allContent 
                            ).map((row, index)=> (
                                <h1></h1>
                            ))} */}

              {contentPPT.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left" scope="row">
                    <Typography sx={{ fontWeight: "bold" }}>PPT</Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() =>
                      row.status === "IMAGE_EXTRACTION_IN_PROGRESS" ||
                      row.status === "NEW"
                        ? alert("No PPT Available")
                        : navigate(`/content/${row.ppt_id}`)
                    }
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    align="left"
                  >
                    {row.ppt_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.status}</StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      onClick={() => pptDownload(row.ppt_uri)}
                      className="card-icons-tray-container"
                    >
                      <DownloadIcon sx={{ cursor: "pointer" }} />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {contentVideo.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left" scope="row">
                    <Typography sx={{ fontWeight: "bold" }}>Video</Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      if (row.video_url === null) {
                        alert("No Video Available");
                        return;
                      }
                      navigate(`/video/${row.video_id}`);
                    }}
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    align="left"
                  >
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.status}</StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      onClick={() => videoDownload(row.video_url)}
                      className="card-icons-tray-container"
                    >
                      <DownloadIcon sx={{ cursor: "pointer" }} />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
